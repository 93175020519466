<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>404 Not found</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>